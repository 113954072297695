import React from "react";

function article_1() {
	return {
	  date: "23 May 2023",  
	  title: "Demystifying DevOps",
	  description: 
		"A Comprehensive Guide to Understanding the Power of DevOps Methodology.",
	  keywords: [
		"Devops",
		"Chirag",
		"Chirag S B",
		"Chirag Biradar",
	  ],
	  style: `
		.article-content {
		  display: flex;
		  flex-direction: column;
		  align-items: center;
		}
  
		.randImage {
		  align-self: center;
		  outline: 2px solid red; 
		}
	  `,
	  body: (
		<React.Fragment>
		  <div className="article-content">
			<div className="paragraph">
			  Content of article 1. See full article a 
			  <a href="https://chiragbiradar.hashnode.dev/demystifying-devops-a-comprehensive-guide-to-understanding-the-power-of-devops-methodology">
				t chiragbiradar.hashnode.dev/demystifying-devops-a-comprehensive-guide-to-understanding-the-power-of-devops-methodology
			  </a>
			</div>
			<img 
			  src="https://browserstack.wpenginepowered.com/wp-content/uploads/2023/02/DevOps-Lifecycle.jpg" 
			  alt="random"
			  className="randImage" 
			/>
		  </div>
		</React.Fragment>
	  )
	};
  }

function article_2() {
	return {
		date: "7 May 2023",
		title: "Step-by-Step Guide to Creating a Scalable Audio to Video Conversion Tool Using Microservices",
		description:
			"Creating a microservice API.",
		style: ``,
		keywords: [
			"Microservices",
			"Chirag",
			"Chirag S B",
			"Chirag Biradar",
		],
		body: (
			<React.Fragment>
				<h1>Content of article 2</h1>
				<div className="paragraph">
			  Content of article 2. See full article a 
			  <a href="https://chiragbiradar.hashnode.dev/step-by-step-guide-to-creating-a-scalable-audio-to-video-conversion-tool-using-microservices">
				t https://chiragbiradar.hashnode.dev/step-by-step-guide-to-creating-a-scalable-audio-to-video-conversion-tool-using-microservices
			  </a>
			</div>
			</React.Fragment>
		),
	};
}

const myArticles = [article_1, article_2];

export default myArticles;

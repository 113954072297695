const SEO = [
	{
		page: "home",
		description:
			"I'm a skilled DevOps and Software Engineer with a strong foundation in optimizing software development and deployment pipelines. My expertise spans a wide range of technologies and methodologies, enabling me to create efficient, secure, and robust systems. From continuous integration to automated deployments, I'm passionate about streamlining processes and enhancing software quality. Explore how my experience and skills can elevate your projects to new heights.",
		keywords: ["Chirag", "Chirag S B", "Chirag Biradar","DevOps Engineer", "Software Engineer", "Continuous Integration", "Deployment Automation", "DevOps Specialist"],
	},

	{
		page: "about",
		description:
			"I'm a skilled DevOps and Software Engineer with a strong foundation in optimizing software development and deployment pipelines. My expertise spans a wide range of technologies and methodologies, enabling me to create efficient, secure, and robust systems. From continuous integration to automated deployments, I'm passionate about streamlining processes and enhancing software quality. Explore how my experience and skills can elevate your projects to new heights.",
		keywords: ["Chirag", "Chirag S B", "Chirag Biradar","DevOps Engineer", "Software Engineer", "Continuous Integration", "Deployment Automation", "DevOps Specialist"],
	},

	{
		page: "articles",
		description:
			"Chronological collection of my long-form thoughts on programming, devops, CICD, leadership, product design, and more.",
		keywords: ["Chirag", "Chirag S B", "Chirag Biradar","DevOps Engineer", "Software Engineer", "Continuous Integration", "Deployment Automation", "DevOps Specialist"],
	},

	{
		page: "projects",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to.",
		keywords: ["Chirag", "Chirag S B", "Chirag Biradar","DevOps Engineer", "Software Engineer", "Continuous Integration", "Deployment Automation", "DevOps Specialist"],
	},

	{
		page: "contact",
		description:
			"If you're interested in collaborating on a project, feel free to reach out to me. I'm always open to new ideas and opportunities.",
		keywords: ["Chirag", "Chirag S B", "Chirag Biradar","DevOps Engineer", "Software Engineer", "Continuous Integration", "Deployment Automation", "DevOps Specialist"],
	},
];

export default SEO;
